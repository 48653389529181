<template>
  <div>
    <div v-if="model === false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
         <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       Geological Engineering Cloud PlatformGPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Distributed Optical Fiber Real-time Monitoring Technology
              </div>
              <div class="title2">
                Utilizing fiber optics as sensors, this technology provides comprehensive, high-precision, and
                long-distance dynamic monitoring services. It offers real-time data transmission, intelligent analysis,
                and precise early warning systems to ensure the safe and efficient development of oil and gas resources.
                Our team has extensive experience in fiber optic monitoring services and strong technical capabilities,
                ensuring high-quality and efficient technical support for clients.
              </div>
              <div class="titlle3" @click="clickConsult('Geological Engineering Cloud Platform')">
                Product Inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <!--分布式光纤实时监测技术-->
          <div class="screenGpts1" style="background-color: white" ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;Distributed Optical Fiber Real-time Monitoring Technology</div>
              </div>
            </div>
            <div class="pro3">
              <!--油田-->
              <div class="grand2" @click="outside">
                <div class="back " style="background-color: #d4e4ff">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px;object-fit: fill;height: 29vh;"
                      src="@/assets/waizhiguangxian.png" class="initImg containImg" />
                  </div>
                  <div class="title1">Permanent outside tubing fiber optic monitoring technology
                  </div>

                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="casing">
                <div class="back" style="background-color: #d4e4ff">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px;object-fit: fill;height: 29vh;"
                      src="@/assets/casing1.png" class="initImg containImg" />
                  </div>
                  <div class="title1">Permanent outside casing fiber optic monitoring technology
                  </div>
                </div>
              </div>
              <div class="grand2" @click="fiber">
                <div class="back" style="background-color: #d4e4ff">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px;object-fit: fill;height: 29vh;"
                      src="@/assets/distributed3.png" class="initImg containImg" />
                  </div>
                  <div class="title1">Fiber optic production and injection profile monitoring technology
                  </div>
                </div>
              </div>
              <div class="grand2" @click="fiberoptic">
                <div class="back" style="background-color: #d4e4ff">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px;object-fit: fill;height: 29vh;"
                      src="@/assets/guagnqianzhaoshui.png" class="initImg containImg" />
                  </div>
                  <div class="title1">Fiber optic water detection monitoring technology
                  </div>
                </div>
              </div>
              <div class="grand2" @click="optical">
                <div class="back" style="background-color: #d4e4ff">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px;object-fit: fill;height: 29vh;"
                      src="@/assets/guangxianzhaolou.png" class="initImg containImg" />
                  </div>
                  <div class="title1">Fiber optic leak detection monitoring technology</div>
                </div>
              </div>
              <div class="grand2" @click="offset">
                <div class="back" style="background-color: #d4e4ff">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px;object-fit: fill;height: 29vh;"
                      src="@/assets/guangxianlingjinyalie.png" class="initImg containImg" />
                  </div>
                  <div class="title1">Fiber optic monitoring technology for neighboring well fracturing</div>
                </div>
              </div>
              <div class="grand2" @click="ccus">
                <div class="back" style="background-color: #d4e4ff">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px;object-fit: fill;height: 29vh;"
                      src="@/assets/guagnxianccusjiance.png" class="initImg containImg" />
                  </div>
                  <div class="title1">Fiber optic CCUS monitoring technology
                  </div>

                </div>
              </div>
              <div class="grand2" @click="pipe">
                <div class="back" style="background-color: #d4e4ff">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px;object-fit: fill;height: 29vh;"
                      src="@/assets/guangxianguandaojiance.png" class="initImg containImg" />
                  </div>
                  <div class="title1">Fiber optic pipeline monitoring technology
                  </div>
                </div>
              </div>
            </div>
          </div>

            <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

        </div>
        <netFeedback  :dialogVisible="dialogNet"
                      @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
           <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       Geological Engineering Cloud PlatformGPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Distributed Optical Fiber Real-time Monitoring Technology
              </div>
              <div class="title2">Utilizing fiber optics as sensors, this technology provides comprehensive,
                high-precision, and long-distance dynamic monitoring services. It offers real-time data transmission,
                intelligent analysis, and precise early warning systems to ensure the safe and efficient development of
                oil and gas resources. Our team has extensive experience in fiber optic monitoring services and strong
                technical capabilities, ensuring high-quality and efficient technical support for clients.</div>
            </div>
            <div class="titlle3" @click="clickConsult('Geological Engineering Cloud Platform')">
              Product inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screen2">
            <div class="pro2">
              <!-- 油管外永置式光纤监测技术-->
              <div class="grand2" @click="goPage('OutsideTubing')">
                <div class="back " style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/waizhiguangxian.png" />
                  </div>
                  <div class="title1">Permanent outside tubing fiber optic monitoring technology


                  </div>
                </div>
              </div>
              <!--套管外永置式光纤监测技术-->
              <div class="grand2" @click="goPage('OutsideCasing')">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/casing1.png" /></div>
                  <div class="title1">Permanent outside casing fiber optic monitoring technology



                  </div>

                </div>
              </div>
              <!--光纤产剖/注剖监测技术-->
              <div class="grand2" @click="goPage('GenerationMonitoring')">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/distributed3.png" /></div>
                  <div class="title1">Fiber optic production and injection profile monitoring technology
                  </div>
                </div>
              </div>
              <!-- 光纤找水监测技术 -->
              <div class="grand2" @click="goPage('WaterFinding')">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/guagnqianzhaoshui.png" /></div>
                  <div class="title1">Fiber optic water detection monitoring technology
                  </div>
                </div>
              </div>
              <!--光纤找漏监测技术  -->
              <div class="grand2" @click="goPage('LeakFinding')">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/guangxianzhaolou.png" /></div>
                  <div class="title1">Fiber optic leak detection monitoring technology
                  </div>
                </div>
              </div>
              <!--光纤邻井压裂监测技术 -->
              <div class="grand2" @click="goPage('FracturingMonitoring')">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/guangxianlingjinyalie.png" /></div>
                  <div class="title1">Fiber optic monitoring technology for neighboring well fracturing
                  </div>
                </div>
              </div>
              <!--光纤CCUS监测技术  -->
              <div class="grand2" @click="goPage('StorageMonitoring')">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/guagnxianccusjiance.png" /></div>
                  <div class="title1">Fiber optic CCUS monitoring technology
                  </div>
                </div>
              </div>
              <!--光纤管道监测技术  -->
              <div class="grand2" @click="goPage('PipelineMonitoring')">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/guangxianguandaojiance.png" /></div>
                  <div class="title1">Fiber optic pipeline monitoring technology
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div ref="bottom">
              <bottomTip  @showDialogBottom="scroll"></bottomTip>

          </div>

        </div>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
  </div>



</template>


<script>import commentFeedback from "@/components/commentFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import netFeedback from "@/components/netFeedback.vue";

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import { shareUrl } from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {  commentFeedback,
    productFeedback,
    netFeedback, PartnerTip, consultTip, headtip, bottomTip },

  data() {
    return {dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      title: '',
      pageTitle: 'Distributed Optical Fiber Real-time Monitoring Technology',
      pageDescription: 'Utilizing fiber optics as sensors, this technology provides comprehensive, high-precision, and long-distance dynamic monitoring services. It offers real-time data transmission, intelligent analysis, and precise early warning systems to ensure the safe and efficient development of oil and gas resources. Our team has extensive experience in fiber optic monitoring services and strong technical capabilities, ensuring high-quality and efficient technical support for clients.',
      pageKeywords: 'Distributed Optical Fiber Real-time Monitoring Technology,Geological Engineering Cloud Platform',

      model: false,
      dialogconsult: false,
      dialogVisible: false,

    }

  },


  methods: {
    hideComment() {
      localStorage.setItem("skip", 0)
      this.dialogComment = false;
    },
    hideProduct(){
      localStorage.setItem("skip", 0)
      this.dialogProduct = false;
    },
    hideNet(){
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },showComment(){
      this.dialogComment=true
      // console.log("chufa")
    },
    showProduct(){
      this.dialogProduct=true
    },
    showNet(){
      this.dialogNet=true
    },
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    goPage(path) {
      window.open("/" + path)
    },
    outside() {
      window.open("/OutsideTubing")
    },
    casing() {
      window.open("/OutsideCasing")
    },
    fiber() {
      window.open("/GenerationMonitoring")
    },
    fiberoptic() {
      window.open("/WaterFinding")
    },
    optical() {
      window.open("/LeakFinding")
    },
    offset() {
      window.open("/FracturingMonitoring")
    },
    ccus() {
      window.open("/StorageMonitoring")
    },
    pipe() {
      window.open("/PipelineMonitoring")
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    }, setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    // Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,

        pageTitle: this.pageTitle, // '智能工程迭代优化GPTs-工程地质技术GPTs-地质工程云平台GPT',
        pageDescription: this.pageDescription, //  '依托云的管理模式，工程服务与地质相结合，立足油气田已有成果，结合基础油藏地质研究，提供实时、动态的优化建议，以达到提升工程效果，降低工程风险的效果。',
        pageKeywords: this.pageKeywords, //  '智能工程迭代优化GPTs,工程地质技术GPTs,地质工程云平台GPT',


      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole {
  background: #d4e4ff;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding:0 0 0 0;
      background-image: url("../../assets/groundHome16.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 30%, rgba(198, 211, 239, 0.4));
      }

        .topback{
    position: fixed;
      z-index: 9;
      width: 99vw;
      padding: 1vh  0;
      background-color: rgb(52, 48, 48,0.3);
  }.layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;
        width: 45%;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: #2f6eff;
          line-height: 1.4;
        }

        .title2 {
          margin-top: 2vh;
          width: 40vw;
          height: fit-content;
          //text-align: justify;
          font-size: 1.0597920277vw;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }

        .titlle3 {
          margin-top: 2vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenGpts1 {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 11.09763142692085vw;
      background: transparent;

      .title {
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        //text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;
      }

      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        row-gap: 2vh;

        .grand2 {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
          }

          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: fit-content;
          }

          .title1 {
            padding: 2vh 0;
            opacity: 4;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            height: fit-content;
            font-size: 1vw;
           font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
            font-weight: bold;
            //color: #2168DB;
            line-height: 3.5vh;
          }

          .title2 {
            opacity: 4;
            margin: 2vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
            //text-align: justify;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            /* 限制在一个块元素显示的文本的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
            justify-content: center;
            align-items: center;
            font-size: 0.9vw;
           font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
            font-weight: 500;
            //color: #2168DB;
            height: 13.5vh;
            line-height: 2.71940667490729vh;
          }

          .consult {
            position: absolute;
            display: none;
           font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
            font-weight: 500;
            font-size: 0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }



    }

    .screenmake4 {
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 8.23733003708282vh;
        font-size: 2.2060658579vw;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 400;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 58vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
              margin: auto;
              color: white;
              width: 35%;
              float: right;

              .title1 {
                font-size: 1.1709416522vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }

                ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 59.87268232385661vh !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }

}

.totalmakeHoleModel {
  background: #d4e4ff;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  img{
    object-fit: contain;
  }

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100%;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 550px;
      padding: 2.84301606922126vh 0 2.84301606922126vh 0;
      background-image: url("../../assets/groundHome16.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;

      /* Add any additional styling as needed */
      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgb(190, 207, 245) 40%, rgba(198, 211, 239, 0.5)),
          linear-gradient(to bottom, rgba(190, 207, 245, 0.5), rgba(198, 211, 239, 0.3));
        /* 从上到下的渐变 */
      }

      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width: fit-content;
          text-align: center;
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;
          height: 22px;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }
      }

      .titlle3 {
        position: absolute;
        bottom: 35px;
        left: 50%;
        transform: translateX(-60%);
        font-size: 14px;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }

    .screen2 {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 10px 15px;
      background: transparent;

      .title {
        height: 8.23733003708282vh;
        opacity: 1;
        text-align: center;
        margin: 0 auto;
        font-size: 1.5vw;
        //color:   #3172dd;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 15px auto 0 auto;
        width: 90%;
        height: fit-content;
        font-size: 14px;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 400;
        color: #2C3E50;
        line-height: 22px;

      }

      .pro2 {
        height: fit-content;
        width: 100%;
        z-index: 89156;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 0;

        .grand2 {
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
          }

          .photo {
            border-radius: 9px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 25vh;
          }

          .title1 {
            padding: 4vh 0;
            opacity: 4;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.71940667490729vh;
            font-size: 0.9rem;
           font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
            font-weight: bold;
            //color: #2168DB;
            line-height: 3.5vh;
            // color: white;
          }

          .title2 {
            opacity: 4;
            margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
            text-align: justify;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.9vw;
           font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }
        }

      }


    }
  }

}
</style>
