<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
         <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       Geological Engineering Cloud Platform 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">About Us</div>
<!--              <div class="title2">安东油气田开发研究院</div>-->

            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="heighLight"> </div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <video id="myVideo" ref="myVideo" width="99%" height="100%" style="border-radius: 9px" controls>
                      <source src="@/video/introduce.mp4" type="video/mp4">
                    </video>

                  </div>

                  <div class="titleDesign">
                    <div class="title2" style="margin-top: 2vh">
                      ANTON reservoir geology research institute keeps the combination of reservoir geology and engineering technology as the central task, provides oilfield clients with scheme customization services with stimulation characteristics in digital and visualized means, to improve development efficiency, which helps clients accurately drill into target reservoirs, and maximize their asset value.
                      Our services include: technological services for reservoir geological research, geological engineering technological services, stimulation technological services, digital reservoir services and turnkey solution stimulation service.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake0">
            <div class="heighLight"></div>   <div class="grid">
              <div class="card">
                <div class="back">


                  <div class="titleDesign">
                    <div>
                      <div class="title2" style="margin-top: 2vh">
                        It has a complete discipline and professional supporting geology and engineering integration technology and management team, providing geology-engineering integration risk general contracting services, with 103 team members (6 Ph.D., 32 masters), covering seismic interpretation, reservoir inversion, geomechanics, three-dimensional geological modeling, numerical simulation, production dynamics, drilling, reservoir transformation, oil and gas testing, etc., and the backbone technical personnel are mostly experts from PetroChina and Sinopec Oilfield Company, with more than 20 years of work experience and high industry recognition.
                      </div>
                    <div class="title4">
                        "3+1" Service Mode
                      </div>
                      <div class="title1">
                        <img loading="lazy" style="border-radius: 9px" src="../assets/ground31.png">
                      </div>
                      <div class="title3" >
                        <img loading="lazy" style="border-radius: 9px" src="../assets/groundabout3.png">
                      </div>
                    </div>
                  </div>
                  <div class="imgDesign" >
                    <img src="@/assets/aboutBack.png">

                  </div>
                </div>
              </div>
            </div>

          </div>
<!--          <div class="screenmake1">-->
<!--            <div class="heighLight">  Business Scope</div>-->
<!--            <div class="grid">-->
<!--              <div class="card">-->
<!--                <div class="back" style="background:linear-gradient(to bottom,rgb(249,251,233) 0%,rgb(248,248,248)100%);">-->
<!--                  <div class="imgDesign">-->
<!--                    <img loading="lazy"  src="../assets/groundabout4.png">-->
<!--                  </div>-->
<!--                  <div class="titleDesign" style="color: rgb(154,131,53) ">-->
<!--                    <div>-->
<!--                      <div class="title1">Reservoir geological research technology GPTs-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        ANTON reservoir geology research institute takes reservoir geological research as the core, applies technologies such as unconventional oil&gas reservoir evaluation, fracture prediction, geomechanical analysis, to provide services for clients in the exploration, production, evaluation and other processes of oilfield development.-->
<!--                        <br>Main services include: fine interpretation of structures, reservoir prediction, geological evaluation, fine description of oil&gas reservoirs, research on oilfield development scheme, dynamic analysis of oilfield development.-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->
<!--              </div>-->
<!--              <div class="card">-->
<!--                <div class="back" style="background:linear-gradient(to bottom,rgb(207,225,255) 0%,rgb(248,248,248)100%);">-->
<!--                  <div class="imgDesign">-->
<!--                    <img loading="lazy"  src="../assets/groundabout5.png">-->

<!--                  </div>-->
<!--                  <div class="titleDesign" style="color: rgb(35,105,219) ">-->
<!--                    <div>-->
<!--                      <div class="title1">Engineering geological technology GPTs-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        Relying on the geological engineering cloud platform, combines engineering means with geology, based on existing achievements of oilfield and basic reservoir geological research, provides clients with real-time and dynamic optimization recommendations. Sharing application and cooperative popularize with clients to provide the “last mile”service, from geology to engineering.-->
<!--                        <br>Main services include: Real-time iterative engineering technological service, geological engineering tracking simulation technological service, on-site geological engineers service and data support service. </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->
<!--              </div>-->
<!--              <div class="card">-->
<!--                <div class="back" style="background:linear-gradient(to bottom,rgb(215,244,250) 0%,rgb(248,248,248)100%);">-->
<!--                  <div class="imgDesign">-->
<!--                    <img loading="lazy"  src="../assets/groundabout6.png">-->

<!--                  </div>-->
<!--                  <div class="titleDesign" style="color: rgb(26,167,172) ">-->
<!--                    <div>-->
<!--                      <div class="title1">Oil & Gas stimulation GPTs-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        Based on reservoir geological research, to provide wide variety of oil & gas reservoir with targeted solution of stimulation technology, to provide clients with one-stop stimulation technological services from scheme design, material selection, on-site implementation, to post-fracturing evaluation.-->
<!--                        Main services include: oil & gas fracturing technology and series of products services, effect monitoring and evaluation technological service of stimulation measures, stimulation technology and series of products for old wells. </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->
<!--              </div>-->
<!--              <div class="card">-->
<!--                <div class="back" style="background:linear-gradient(to bottom,rgb(255,220,201) 0%,rgb(248,248,248)100%);">-->
<!--                  <div class="imgDesign">-->
<!--                    <img loading="lazy"  src="../assets/groundabout7.png">-->

<!--                  </div>-->
<!--                  <div class="titleDesign" style="color: rgb(225,94,2) ">-->
<!--                    <div>-->
<!--                      <div class="title1">Increasing production overall solution technical services-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        ANTON reservoir geological research institute aims at unconventional oil-gas reservoir, focuses on production raising, features in geological engineering, integrates advanced design concept, new processing and technology, innovates business and production organization mode, thus providing clients with turnkey solution stimulation service for different types of single wells or blocks to maximize the value of reservoirs.  </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->
<!--              </div>-->
<!--              <div class="card">-->
<!--                <div class="back" style="background:linear-gradient(to bottom,rgb(255,217,245) 0%,rgb(248,248,248)100%);">-->
<!--                  <div class="imgDesign">-->
<!--                    <img loading="lazy"  src="../assets/groundabout8.png">-->

<!--                  </div>-->
<!--                  <div class="titleDesign" style="color: rgb(203,65,146) ">-->
<!--                    <div>-->
<!--                      <div class="title1">Dynamic monitoring for oil & gas field developmen GPTs-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        Through real-time data collection, analysis and processing, predictive modeling, visualization, and decision support, dynamic monitoring GPTs assist oil and gas field managers in optimizing resource allocation, improving production efficiency, and effectively identifying and managing potential risks. This ensures production safety, promotes environmental protection, and guarantees timely information transmission and processing, providing a comprehensive solution for real-time dynamic monitoring and analysis in oil and gas field development. It drives the digital transformation and sustainable development of oil and gas exploration.  </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->
<!--              </div>            </div>-->

<!--          </div>-->
<!--          <div class="screenmake0">-->
<!--            <div class="heighLight"> Our Organization</div>-->
<!--            <div class="grid" style="margin-top: 4vh">-->
<!--              <div style="width: 100%;height: fit-content">-->
<!--                <img loading="lazy"  src="../assets/groundaboutor.png">-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--          <div class="screenmake2">-->
<!--            <div class="heighLight"> Development History</div>-->
<!--            <div class="grid">-->
<!--              <div style="width: 100%;height: fit-content">-->
<!--                <img loading="lazy"  src="../assets/groundaboutor2.png">-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->

<!--          <div class="screenmake3">-->
<!--            <div class="heighLight"> Talent Recruitment</div>-->
<!--            <div class="grid">-->
<!--              <div class="card">-->
<!--                <div class="back">-->
<!--                  <div class="imgDesign">-->
<!--                    <img loading="lazy"  src="../assets/groundabout8.jpg">-->

<!--                  </div>-->

<!--                  <div class="titleDesign">-->
<!--                    <div style="list-style: none;border-radius: 9px;margin-top: 5vh">-->
<!--                      <div class="more" style="color: white;font-weight: bold;border-bottom: white 1px solid" @click="skipto">Anton Recruitment</div>-->
<!--                      <dv-scroll-board  @click="skipto" class="dv-scroll-board"  :config="config"  />-->
<!--                      <div class="more" style="color: white;font-weight: bold;"  @click="skipto">More...</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
            <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip><netFeedback  :dialogVisible="dialogNet"
              @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
           <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       Geological Engineering Cloud Platform 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">About Us</div>
            </div>
            <div class="titlle3" @click="clickConsult('Geological Engineering Cloud Platform')">
              Product Inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="heighLight"> Research Institute Introduction</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <video id="myVideo" ref="myVideo" width="99%" height="100%" style="border-radius: 9px" controls>
                      <source src="@/video/introduce.mp4" type="video/mp4">
                    </video>

                  </div>

                  <div class="titleDesign">
                    <div>

                      <div class="title2" style="margin-top: 2vh">
                        ANTON reservoir geology research institute keeps the combination of reservoir geology and engineering technology as the central task, provides oilfield clients with scheme customization services with stimulation characteristics in digital and visualized means, to improve development efficiency, which helps clients accurately drill into target reservoirs, and maximize their asset value.
                        Our services include: technological services for reservoir geological research, geological engineering technological services, stimulation technological services, digital reservoir services and turnkey solution stimulation service.
                      </div>
                      <div class="title4">
                        "3+1" Service Mode
                      </div>
                      <div class="title1">
                        <img loading="lazy" style="border-radius: 9px" src="../assets/ground31.png">
                      </div>
                      <div class="title3" >
                        <img loading="lazy" style="border-radius: 9px" src="../assets/groundabout3.png">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">  Business Scope</div>
            <div class="grid">
              <div class="card">
                <div class="back" style="background:linear-gradient(to bottom,rgb(249,251,233) 0%,rgb(248,248,248)100%);">
                  <div class="imgDesign">
                    <img loading="lazy"  src="../assets/groundabout4.png">
                  </div>
                  <div class="titleDesign" style="color: rgb(154,131,53) ">
                    <div>
                      <div class="title1">Reservoir geological research technology GPTs
                      </div>
                      <div class="title2">
                        ANTON reservoir geology research institute takes reservoir geological research as the core, applies technologies such as unconventional oil&gas reservoir evaluation, fracture prediction, geomechanical analysis, to provide services for clients in the exploration, production, evaluation and other processes of oilfield development.
                        <br>Main services include: fine interpretation of structures, reservoir prediction, geological evaluation, fine description of oil&gas reservoirs, research on oilfield development scheme, dynamic analysis of oilfield development.
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="card">
                <div class="back" style="background:linear-gradient(to bottom,rgb(207,225,255) 0%,rgb(248,248,248)100%);">
                  <div class="imgDesign">
                    <img loading="lazy"  src="../assets/groundabout5.png">

                  </div>
                  <div class="titleDesign" style="color: rgb(35,105,219) ">
                    <div>
                      <div class="title1">Engineering geological technology GPTs
                      </div>
                      <div class="title2">
                        Relying on the geological engineering cloud platform, combines engineering means with geology, based on existing achievements of oilfield and basic reservoir geological research, provides clients with real-time and dynamic optimization recommendations. Sharing application and cooperative popularize with clients to provide the “last mile”service, from geology to engineering.
                        <br>Main services include: Real-time iterative engineering technological service, geological engineering tracking simulation technological service, on-site geological engineers service and data support service. </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="card">
                <div class="back" style="background:linear-gradient(to bottom,rgb(215,244,250) 0%,rgb(248,248,248)100%);">
                  <div class="imgDesign">
                    <img loading="lazy"  src="../assets/groundabout6.png">

                  </div>
                  <div class="titleDesign" style="color: rgb(26,167,172) ">
                    <div>
                      <div class="title1">Oil & Gas stimulation GPTs
                      </div>
                      <div class="title2">
                        Based on reservoir geological research, to provide wide variety of oil & gas reservoir with targeted solution of stimulation technology, to provide clients with one-stop stimulation technological services from scheme design, material selection, on-site implementation, to post-fracturing evaluation.
                        Main services include: oil & gas fracturing technology and series of products services, effect monitoring and evaluation technological service of stimulation measures, stimulation technology and series of products for old wells. </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="card">
                <div class="back" style="background:linear-gradient(to bottom,rgb(255,220,201) 0%,rgb(248,248,248)100%);">
                  <div class="imgDesign">
                    <img loading="lazy"  src="../assets/groundabout7.png">

                  </div>
                  <div class="titleDesign" style="color: rgb(225,94,2) ">
                    <div>
                      <div class="title1">Increasing production overall solution technical services
                      </div>
                      <div class="title2">
                        ANTON reservoir geological research institute aims at unconventional oil-gas reservoir, focuses on production raising, features in geological engineering, integrates advanced design concept, new processing and technology, innovates business and production organization mode, thus providing clients with turnkey solution stimulation service for different types of single wells or blocks to maximize the value of reservoirs.  </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="card">
                <div class="back" style="background:linear-gradient(to bottom,rgb(255,217,245) 0%,rgb(248,248,248)100%);">
                  <div class="imgDesign">
                    <img loading="lazy"  src="../assets/groundabout8.png">

                  </div>
                  <div class="titleDesign" style="color: rgb(203,65,146) ">
                    <div>
                      <div class="title1">Dynamic monitoring for oil & gas field developmen GPTs
                      </div>
                      <div class="title2">
                        Through real-time data collection, analysis and processing, predictive modeling, visualization, and decision support, dynamic monitoring GPTs assist oil and gas field managers in optimizing resource allocation, improving production efficiency, and effectively identifying and managing potential risks. This ensures production safety, promotes environmental protection, and guarantees timely information transmission and processing, providing a comprehensive solution for real-time dynamic monitoring and analysis in oil and gas field development. It drives the digital transformation and sustainable development of oil and gas exploration.  </div>
                    </div>
                  </div>

                </div>
              </div>            </div>

          </div>
          <div class="screenmake0">
            <div class="heighLight"> Our Organization</div>
            <div class="grid" style="margin-top: 4vh">
              <div style="width: 100%;height: fit-content">
                <img loading="lazy"  src="../assets/groundaboutor.png">
              </div>
            </div>

          </div>
          <div class="screenmake2">
            <div class="heighLight"> Development History</div>
            <div class="grid">
              <div style="width: 100%;height: fit-content">
                <img loading="lazy"  src="../assets/groundaboutor2.png">
              </div>
            </div>

          </div>

          <div class="screenmake3">
            <div class="heighLight"> Talent Recruitment</div>
            <div class="grid" style="margin-top: -20px">
              <div class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div style="list-style: none;border-radius: 9px;margin-top: 5vh">
                      <div class="more" style="color: white;font-weight: bold;border-bottom: white 1px solid" @click="skipto">Anton Recruitment</div>
                      <dv-scroll-board  @click="skipto" class="dv-scroll-board"  :config="config"  />
                      <div class="more" style="color: white;font-weight: bold;"  @click="skipto">More...</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref="bottom">
              <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip><netFeedback  :dialogVisible="dialogNet"
              @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>import commentFeedback from "@/components/commentFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import netFeedback from "@/components/netFeedback.vue";

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],
  components: {  commentFeedback,
    productFeedback,
    netFeedback,PartnerTip, consultTip, headtip,bottomTip},

  data() {
    return {dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      title:'',
      pageTitle: 'About Us-Geological Engineering Cloud Platform',
      pageDescription: 'ANTON reservoir geology research institute keeps the combination of reservoir geology and engineering technology as the central task, provides oilfield clients with scheme customization services with stimulation characteristics in digital and visualized means, to improve development efficiency, which helps clients accurately drill into target reservoirs, and maximize their asset value. Our services include: technological services for reservoir geological research, geological engineering technological services, stimulation technological services, digital reservoir services and turnkey solution stimulation service.',
      pageKeywords: 'Geological Engineering Cloud Platform,About Us',
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      configmobile: {
        headerBGC:'rgba(16,31,115,0.5)',
        oddRowBGC:'rgba(75,173,197,0.1)',
        evenRowBGC:'rgba(48,92,194,0.1)',
        rowNum:6,
        header: ['Job', 'Department', 'Address'],
        align:['center','center','center'],
        data: [],
        waitTime:2000,
      },
      config: {
        headerBGC:'rgba(16,31,115,0.5)',
        oddRowBGC:'rgba(75,173,197,0.1)',
        evenRowBGC:'rgba(48,92,194,0.1)',
        rowNum:4,
        header: ['Job', 'Department', 'Address'],
        align:['center','center','center'],
        data: [
          ["Fracturing Design Engineer",
            " Oil & Gas Field Development Research Institute",
            " Chengdu/Zhengzhou"],
          ["Oil and Gas Reservoir Engineering Expert",
            " Oil & Gas Field Development Research Institute",
            " Kuerle"],
          ["Developing geological technology expert",
            " Oil & Gas Field Development Research Institute",
            " Kuerle"],
          [" Seismic Interpretation expert ",
            " Oil & Gas Field Development Research Institute",
            " Kuerle"],
          ["Fracturing Fluid Project Manager",
            " Oil & Gas Field Development Research Institute",
            " Chengdu/Zhengzhou"],

        ],
        waitTime:2000,
      },
    }

  },


  methods: {
    skipto(config){
      console.log('config', config.row)
      window.open("https://wecruit.hotjob.cn/SU64db9dff6202cc36e28c3a96/pb/index.html#/")
    },
    getTalent(){
      this.$axios
          .get("/info/job/list",{
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            this.job = res.data.rows

          })
          .catch((err) => {
            console.log(err);
          });
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    const videoEl = this.$refs.myVideo;
    videoEl.play();
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  created() {
    this.getTalent()
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'About Us-Geological Engineering Cloud Platform',
        pageDescription: '安东油藏地质研究院以油藏地质和工程技术相结合为核心，配合数字化、可视化手段，向油田客户提供具有增产特色的方案定制化服务，提高开发效率，帮助客户精准命中目标油气藏，使客户油藏资产价值最大化。 业务包括油藏地质研究技术服务、Engineering Geological Technology GPTs、Oil & Gas Stimulation GPTs、油藏数字化服务以及Increasing Production Overall Solution Technical Services。',
        pageKeywords: 'Geological Engineering Cloud Platform,About Us',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 70vh;

      padding: 0 0 0 0;
      background-image: url("../assets/groundabout.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

        .topback{
    position: fixed;
      z-index: 9;
      width: 99vw;
      padding: 1vh  0;
      background-color: rgb(52, 48, 48,0.3);
  }.layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: #2f6eff;
          line-height: 3vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;

          font-size: 1.0597920277vw;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }
        .titlle3{
          margin-top: 8vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake0{
      padding: 5.78739184177998vh 4vw 5.78739184177998vh 6vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        font-weight: bold;
        margin: 0 auto;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size:1.5vw;
        color: #3a75fe;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 4.23733003708282vh;
        width: fit-content;
        //border-bottom: #5798f2 5px solid;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: white;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content ;
            overflow:hidden;
            .imgDesign{
              width:55%;
              //margin-top: 5%;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 0 4.5248454883vw 2vh 2%;
              margin:auto 1vw auto auto;
              color: #3a75fe;
              width: 45%;
              float: right;
              position: relative;
              .title1{
                position: absolute;
                margin: 2vh auto;
                width: 82%;
                height: fit-content;
                font-size: 1.2vw;

                font-weight: bold;
              }
              .title2{

                //text-align: justify;
                width: 100%;
                margin: 2vh auto 0 auto;
                line-height: 4.5vh;
                font-size: 1.3vw;;
              }
              .title3{
                margin: 7.5vh auto 0 auto;
                width: 70%;
                display: grid;
                grid-template-columns: minmax(0,1fr);
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }
              .title4{
                position: absolute;
                margin: 3vh auto 0 auto;
                left:50%;
                transform: translateX(-20%);
                width: 82%;
                z-index: 99999;
                color: white;
                height: fit-content;
                font-size:1.2vw;
                font-weight: bold;
              }

            }

          }
        }


      }
    }
    .screenmake1{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;
    .heighLight {
               margin: 0 auto;
               text-align: center;
               height:fit-content;
               opacity: 1;
               font-size:1.5vw;
               color: #3a75fe;
              font-weight: bold;
              font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
               line-height: 4.23733003708282vh;
               width: fit-content;
               //border-bottom: #5798f2 5px solid;

             }
      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      }
      .grid{
        margin-top:4.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 1.5vw;
        row-gap: 2vh;
        .card{
          transition:transform 0.3s ease-out;
          //cursor: pointer;
          border: white 1px solid;
          border-radius: 9px;
          width: 100%;
          height:fit-content ;
          opacity: 1;

          .back{
            background: transparent;
            border-radius: 9px;
            position: relative;
            height:95vh;
            overflow:hidden;
            .imgDesign{
              width:20%;
              margin: 5% auto 0 auto;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 2vh 0 2.5248454883vh 0;
              margin:auto 1vw auto auto;

              color: black;
              width:100%;
              .title1{
                margin: 0 auto 2vh auto;
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
                text-align: center;
                height: 7vh;
              }
              .title2{

                width: 90%;
                margin: 3vh auto 0 auto;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

            }

          }
        }
        .card:hover{
          .imgDesign {
            transform:scale(1.12);
          }
        }

      }

    }
    .screenmake2{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        font-weight: bold;
        margin: 0 auto;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size:1.5vw;
        color: #3a75fe;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 4.23733003708282vh;
        width: fit-content;
        //border-bottom: #5798f2 5px solid;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      }
      .grid{
        margin-top:4.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:minmax(0, 1fr);
        column-gap: 0;
        row-gap: 2vh;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: transparent;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:66.87144622991347vh ;
            overflow:hidden;
            .imgDesign{
              width:15%;
              margin: 5% auto 0 auto;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 4.5248454883vh 2.5248454883vw 2.5248454883vh 2%;
              margin:0 1vw auto auto;
              color: black;
              width: 45%;
              float: right;
              .title1{
                margin: 2vh auto 0 auto;
                width: 65%;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{

                width: 90%;
                margin: 0 auto 0 auto;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

            }

          }
        }

;
      }
    }
    .screenmake3{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      ::v-deep .el-table .warning-row {
        background: oldlace;
      }

      ::v-deep .el-table .success-row {
        background: #f0f9eb;
      }
      .dv-scroll-board {

        height: 50vh;
        border-radius: 9px;
        cursor: pointer;
        background-color: #ebf1fd !important;
        color: #333333 !important;
      }

      ::v-deep .dv-scroll-board .rows :hover {
        background-color: #d8d9dc !important;
        color: #333333 !important;
      }
      .more{
        height: 7vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #7e89b9;
        cursor: pointer;
      }
      .more:hover{
        background-color: #d8d9dc !important;

      }
      .heighLight {
        font-weight: bold;
        margin: 0 auto;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size:1.5vw;
        color: #3a75fe;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 4.23733003708282vh;
        width: fit-content;
        //border-bottom: #5798f2 5px solid;

      }
      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: transparent;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content ;
            overflow:hidden;
            .imgDesign{
              width:fit-content;
              height: 35vw;
              opacity: 1;
            }
            .titleDesign{
              padding: 0 0 2.5248454883vh 3vw;
              margin:0 1vw auto auto;
              color: black;
              width: 50%;
              float: right;
              .title1{
                margin: 2vh auto 0 auto;
                width: 65%;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                //text-align: justify;
                width: 90%;
                margin: 0 auto 0 auto;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

            }

          }
        }


      }
    }
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 300px;
      padding: 2.84301606922126vh 0  2.84301606922126vh 0;
      background-image: url("../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 100px;
        width: 100%;

        .title1 {
          width:fit-content;
          text-align: center;
          display: flex;
          margin:0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }
        .title4{
          margin-top: 15px;

          height: 22px;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          font-size:16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }
    .screenmake0{
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 18px;
        margin: 0 auto;
        padding: 0 0 5px 0;
        width: fit-content;
        border-bottom: #3163c5 5px solid;
        //color: #53A3F7;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;

        width: fit-content;
        height: fit-content;
        font-size:14px;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        margin-top:25px;
        .titleDesign{
          padding: 15px 0 2.5248454883vh 0;
          margin:0 1vw auto auto;
          color: black;
          width:100%;
          .title1{
            margin: 15px auto 0 auto;
            width:100%;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            //text-align: justify;
            width: 90%;
            margin: 15px auto 0 auto;
            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            //text-align: justify;
            width: 80%;
            margin: -125px auto 0 auto;
            line-height: 22px;
            font-size: 14px;
          }
          .title4{
            //text-align: justify;
            width: 80%;
            position: absolute;
            margin: 15px auto 0 auto;
            left:50%;
            color: white;
            transform: translateX(-20%);
            line-height: 22px;
            font-size: 14px;
          }

        }
        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: transparent;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 18px;
        margin: 0 auto;
        padding: 0 0 5px 0;
        width: fit-content;
        border-bottom: #3163c5 5px solid;
        //color: #53A3F7;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 700;
        line-height: 20px;

      }
      .heightLight2{
        margin:19px 2vw;

        width: fit-content;
        height: fit-content;
        font-size:14px;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        margin-top:4.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 1.5vw;
        row-gap: 2vh;
        .card{
          transition:transform 0.3s ease-out;
          //cursor: pointer;
          //background-color: white;
          border-radius: 9px;
          width: 100%;
          min-height: fit-content;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: transparent;
            border-radius: 9px;
            position: relative;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width:20%;
              margin: 5% auto 0 auto;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 15px 0 2.5248454883vh 0;
              margin:0 1vw auto auto;
              color: black;
              width:100%;
              .title1{
                margin: 0 16px 15px 16px ;
                width: fit-content;
                text-align: center;
                font-size: 14px;
                height:fit-content;
                line-height: 22px;
                font-weight: bold;
              }
              .title2{

                width: 95%;
                margin: 15px auto 0 auto;
                line-height: 22px;
                font-size: 14px;
              }

            }

          }
        }
        .card:hover{
          .imgDesign {
            transform:scale(1.12);
          }
        }

        .card1{
          transition:transform 0.3s ease-out;
          //cursor: pointer;
          background-color: white;
          border-radius: 9px;
          width: 100%;
          min-height:700px;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: transparent;
            border-radius: 9px;
            position: relative;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width:20%;
              margin: 5% auto 0 auto;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 15px 0 2.5248454883vh 0;
              margin:0 1vw auto auto;
              color: black;
              width:100%;
              .title1{
                margin: 0 auto 0 auto;
                width: fit-content;
                text-align: center;
                font-size: 14px;
                height:58px;
                line-height: 22px;
                font-weight: bold;
              }
              .title2{

                width: 95%;
                margin: 15px auto 0 auto;
                line-height: 22px;
                font-size: 14px;
              }

            }

          }
        }
        .card1:hover{
          .imgDesign {
            transform:scale(1.12);
          }
        }
      }

    }
    .screenmake2{
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: transparent;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 18px;
        margin: 0 auto;
        padding: 0 0 5px 0;
        width: fit-content;
        border-bottom: #3163c5 5px solid;
        //color: #53A3F7;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 700;
        line-height: 20px;

      }
      .heightLight2{
        margin:19px 2vw;

        width: fit-content;
        height: fit-content;
        font-size:14px;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        margin-top:4.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:minmax(0, 1fr);
        column-gap: 0;
        row-gap: 2vh;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: transparent;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:66.87144622991347vh ;
            overflow:hidden;
            .imgDesign{
              width:15%;
              margin: 5% auto 0 auto;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 4.5248454883vh 2.5248454883vw 2.5248454883vh 2%;
              margin:0 1vw auto auto;
              color: black;
              width: 45%;
              float: right;
              .title1{
                margin: 2vh auto 0 auto;
                width: 65%;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{

                width: 90%;
                margin: 0 auto 0 auto;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

            }

          }
        }


      }
    }

    .screenmake3{
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 18px;
        margin: 0 auto;
        padding: 0 0 5px 0;
        width: fit-content;
        border-bottom: #3163c5 5px solid;
        //color: #53A3F7;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 700;
        line-height: 20px;

      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .dv-scroll-board {
            height: 350px;
            border-radius: 9px;
            cursor: pointer;
            background-color: #ebf1fd !important;
            color: black !important;
          }

          .more{
            height:32px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #7e89b9;
            cursor: pointer;
          }


          .back{
            background: transparent;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content;
            overflow:hidden;
            .titleDesign{
              padding: 0 2% 2.5248454883vh 2%;
              margin:0 auto auto auto;
              color: black;
              width: 100%;
              float: right;
              .title1{
                margin: 2vh auto 0 auto;
                width: 100%;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                //text-align: justify;
                width: 90%;
                margin: 0 auto 0 auto;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

            }

          }
        }


      }
    }
  }
}
</style>
