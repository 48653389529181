
<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
         <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       Geological Engineering Cloud PlatformGPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Petroleum enzyme plugging removal technology
              </div>
              <div class="title2">Petroleum enzyme is a kind of bio-based material with catalysis, which can significantly restore the flow capacity of formation crude oil. It is an international leading mature product developed by using modern biological engineering technologies such as genetic engineering, cell engineering, and enzyme engineering to mutate and breed new strains. It has the ability to efficiently release crude oil on the surface of solid particles. It has the characteristics of catalytic oil washing activity, changing rock wettability and reducing interfacial tension.</div>
              <div class="titlle3" @click="clickConsult('Geological Engineering Cloud Platform')">
                Product inquiry
              </div>
            </div>
            <div class="layer3"></div>
          </div>
          <div class="screenGpts" style="background-color: white"   ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <div class="title">&nbsp;Petroleum enzyme plugging removal technology
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2">
                <div class="back "  style="background-color: white">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw;width: 60%">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/enzyme1.png"/>
                  </div>

                  <div class="titleDesign" style="background-color: #bfd8f9">
                    <div class="title1">Scope of application </div>
                    <div class="title2">Viscosity of underground crude oil is less than10000mPa.s, formation temperature is less than180°C, oil saturation > 10%, reservoir permeability > 0.1mD, lithology is not limited, the salinity of formation water is less than 20×10<sup>⁴</sup>mg/L.</div>
                    <div class="title1" style="margin-top: 2vh">Technological superiority： </div>
                    <div class="title2" >
                      <li>Temperature resistance: The reservoir below 180 °C is generally applicable;</li>
                      <li>Salt resistance: The stratum below 20 × 10<sup>4</sup> mg/L is applicable;</li>
                      <li>Environment-friendly: Biological metabolites, safety;</li>
                      <li>High efficiency: Rapid stripping of crude oil on the surface of rock, degradate macromolecule rapidly;</li>
                      <li>Easy to demulsify: The enzyme and crude oil are separated automatically, and the viscosity increasing performance is strong;</li>
                        </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake4">
            <div class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">

                      <div style="background-color: white;width: 55%">
                        <div class="imgDesign">
                          <img loading="lazy" :src="item.img"/>
                        </div>
                      </div>

                      <div class="titleDesign">
                        <div class="title2" v-html="item.title2"></div>
                        <div class="title1" v-html="item.title1"></div>
                        <div class="title2" v-html="item.title3"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
            <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip><netFeedback  :dialogVisible="dialogNet"
              @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
           <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       Geological Engineering Cloud PlatformGPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Petroleum enzyme plugging removal technology</div>
              <div class="title2">Petroleum enzyme is a kind of bio-based material with catalysis, which can significantly restore the flow capacity of formation crude oil. It is an international leading mature product developed by using modern biological engineering technologies such as genetic engineering, cell engineering, and enzyme engineering to mutate and breed new strains. It has the ability to efficiently release crude oil on the surface of solid particles. It has the characteristics of catalytic oil washing activity, changing rock wettability and reducing interfacial tension.</div>
            </div>
            <div class="titlle3" @click="clickConsult('Geological Engineering Cloud Platform')">
              Product inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <!--Scope of application  -->
          <div class="screenmake0">
            <div class="heighLight">Scope of application
            </div>
            <div class="heightLight2">
              Viscosity of underground crude oil is less than10000mPa.s, formation temperature is less than180°C, oil saturation > 10%, reservoir permeability > 0.1mD, lithology is not limited, the salinity of formation water is less than 20×10<sup>⁴</sup>mg/L.
            </div>
          </div>
          <!--Technological superiority： -->
          <div class="screenmake0" style="background: transparent;">
            <div class="heighLight"> Technological superiority
            </div>
            <div class="heightLight2">
              <li>Temperature resistance: The reservoir below 180 °C is generally applicable;</li>
                      <li>Salt resistance: The stratum below 20 × 10<sup>4</sup> mg/L is applicable;</li>
                      <li>Environment-friendly: Biological metabolites, safety;</li>
                      <li>High efficiency: Rapid stripping of crude oil on the surface of rock, degradate macromolecule rapidly;</li>
                      <li>Easy to demulsify: The enzyme and crude oil are separated automatically, and the viscosity increasing performance is strong;</li>
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/enzyme1.png" />
            </div>
          </div>
          <!--Successful Cases  -->
          <div class="screenmake4">
            <div class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div style="background-color: white;width: 100%">
                        <div class="imgDesign">
                          <img loading="lazy" style="object-fit: scale-down" :src="item.img" />
                        </div>
                      </div>
                      <div class="titleDesign">
                        <div class="title2" v-html="item.title2"></div>
                        <div class="title1" v-html="item.title1"></div>
                        <div class="title2" v-html="item.title3"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div ref="bottom">
              <bottomTip  @showDialogBottom="scroll"></bottomTip>

          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>import commentFeedback from "@/components/commentFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import netFeedback from "@/components/netFeedback.vue";

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {  commentFeedback,
    productFeedback,
    netFeedback,PartnerTip, consultTip, headtip,bottomTip},

  data() {
    return {dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      title:'',

      pageTitle: 'Petroleum enzyme plugging removal technology-Geological Engineering Cloud PlatformGPT',
      pageDescription: 'Petroleum enzyme is a kind of bio-based material with catalysis, which can significantly restore the flow capacity of formation crude oil. It is an international leading mature product developed by using modern biological engineering technologies such as genetic engineering, cell engineering, and enzyme engineering to mutate and breed new strains. It has the ability to efficiently release crude oil on the surface of solid particles. It has the characteristics of catalytic oil washing activity, changing rock wettability and reducing interfacial tension.',
      pageKeywords: 'Petroleum enzyme plugging removal technology,Geological Engineering Cloud PlatformGPT',

      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/enzyme2.png"),
        title2:"Tests have been carried out in more than 200 different types of oilfields under construction. The average production increase of a single well is more than 30%, the cumulative production increase is more than 100,000 tons, and the average single well production increase is more than 500 tons. The input-output ratio of petroleum enzyme measures is 1:2 ~ 1:9.21. According to the effect of statistical measures, 1 ton of petroleum enzyme can replace 100 to 200 tons of crude oil.",
        title1:"SQXXXX well",
        title3:"" +
            "<li>After 463 days, the average liquid production is 12.2 square / day, the average oil production is 6.05 tons / day, and the cumulative oil production is 2200 tons.</li>"
      }, {
        img: require("@/assets/enzyme3.png"),
       title1:"Feng XX well",
        title3:"" +
            "<li>As of May 14, 2023, 119 days after the measures were taken, the cumulative oil production was 496.09 t, and the cumulative oil production was 270 t, which is still in normal production. The average daily oil increase after the measures is 2.27 t/d ; the production time was prolonged by 84% after the measures were taken.</li>"
      }
      ]
    }

  },


  methods: {    hideComment() {
      localStorage.setItem("skip", 0)
      this.dialogComment = false;
    },
    hideProduct(){
      localStorage.setItem("skip", 0)
      this.dialogProduct = false;
    },
    hideNet(){
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },showComment(){
      this.dialogComment=true
      // console.log("chufa")
    },
    showProduct(){
      this.dialogProduct=true
    },
    showNet(){
      this.dialogNet=true
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    realtime(){
      window.open("/realtime")
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Petroleum enzyme plugging removal technology-Geological Engineering Cloud PlatformGPT',
        pageDescription: 'Petroleum enzyme is a kind of bio-based material with catalysis, which can significantly restore the flow capacity of formation crude oil. It is an international leading mature product developed by using modern biological engineering technologies such as genetic engineering, cell engineering, and enzyme engineering to mutate and breed new strains. It has the ability to efficiently release crude oil on the surface of solid particles. It has the characteristics of catalytic oil washing activity, changing rock wettability and reducing interfacial tension.',
        pageKeywords: 'Petroleum enzyme plugging removal technology,Geological Engineering Cloud PlatformGPT',


      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding: 0 0 0 0;
      background-image: url("../../assets/groundHome6new.png");
      background-position: 100% center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      & > div{
        position: relative;
        z-index: 10;
      }
      &::after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 50%, rgba(198, 211, 239, 0.5));
      }

        .topback{
    position: fixed;
      z-index: 9;
      width: 99vw;
      padding: 1vh  0;
      background-color: rgb(52, 48, 48,0.3);
  }.layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2vw;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: #2f6eff;
          line-height: 5vh;
        }

        .title2 {
          margin-top: 3vh;
          width: 40vw;
          height: fit-content;
          ////text-align: justify;
          font-size: 1.0597920277vw;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: black;
          line-height: 4vh;
        }
        .titlle3{
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        ////text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns:minmax(0, 1fr);
        row-gap: 2vh;
        .grand2 {
          //cursor: pointer;
          width: 100%;
          height:fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: fit-content;
            height: 100%;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: left;
            font-size: 1vw;
           font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
            font-weight: bold;
            //color: #2168DB;
            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            ////text-align: justify;
            justify-content: center;
            align-items: center;
            font-size:0.9vw;
           font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }
          .titleDesign{
            margin:auto;
            padding: 1vh 1vw;
            width: 50%;
            float: right;
            ////text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
           font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }



    }

    .screenmake4{
      padding: 5vh 3.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #212a3c;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:5.23733003708282vh;
        font-size: 1.5vw;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: bold;
        color:white;
        line-height: 4.23733003708282vh;

      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 86%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:50vh ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              background-color: white;
              width: fit-content;
              height:80%;
              margin:5% auto;
              opacity: 1;
            }
            .titleDesign{
              margin:auto;
              color: white;
              width: 35%;
              float: right;
              text-align: left;
              .title1{
                line-height: 4vh;
                font-size: 1.1709416522vw;
              }
              .title2{
                line-height: 3.5vh;
                font-size: 0.9vw;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }
        ::v-deep .el-carousel__container{
          height:52vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}

.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 580px;
      padding: 2.84301606922126vh 0 2.84301606922126vh 0;
      background-image: url("../../assets/groundHome6new.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;

      /* Add any additional styling as needed */
      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgb(190, 207, 245) 40%, rgba(198, 211, 239, 0.5)),
          linear-gradient(to bottom, rgba(190, 207, 245, 0.5), rgba(198, 211, 239, 0.3));
        /* 从上到下的渐变 */
      }

      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width: fit-content;
          text-align: center;
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;
          height: 22px;
         font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }
      }

      .titlle3 {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-60%);
        font-size: 14px;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }

    .screenmake0 {
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 400;
        color: #2C3E50;
        line-height: 21px;

        h4 {
          margin-bottom: 3px;
          margin-top: 5px;
        }

        p {
          margin-bottom: 2px;
          line-height: 21px;
        }
      }

      .grid {
        position: relative;
        height: fit-content;
        margin-top: 25px;

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4 {
      padding: 20px 0;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
       font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 700;
        line-height: 26px;
        color: #fff;
      }

      .grid {
        margin-top: 25px;
        position: relative;

        .card {
          width: 80%;
          margin: 0 auto;
          height: fit-content;
          opacity: 1;
          background: #19202e;
          border-radius: 9px;

          .back {
            border-radius: 9px;
            display: flex;
            flex-direction: column;
            position: relative;
            height: 550px;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              background-color: white;
              width: fit-content;
              height: 150px;
              margin: 0 auto;
              opacity: 1;
            }

            .titleDesign {
              margin: 10px auto;
              color: white;
              // width: 35%;
              // float: right;

              // text-align: left;
              .title1 {
                line-height: 21px;
                font-size: 14px;
                font-weight: bold;
              }

              .title2 {
                line-height: 21px;
                font-size: 14px;
                padding: 0 10px;

                ::v-deep li {
                  margin-top: 1px;
                }

                ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 550px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0 -15px;
          //cursor: pointer;

          border-radius: 50%;
          width: 35px;
          height: 35px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }

}
</style>
