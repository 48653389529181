import { render, staticRenderFns } from "./special.vue?vue&type=template&id=6b37edea&scoped=true"
import script from "./special.vue?vue&type=script&lang=js"
export * from "./special.vue?vue&type=script&lang=js"
import style0 from "./special.vue?vue&type=style&index=0&id=6b37edea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b37edea",
  null
  
)

export default component.exports